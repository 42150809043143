
import { createModel } from '@rematch/core'
import type { RootModel } from '.'
import { createUser, getUser } from '../../api';
import socket from '../../socket'
export interface User {
  displayName: string | null;
  userNumber: string | null;
  isLoading: boolean;
  currentRoom: string;
  animal: string;
  color: string;
}

const initialState: User = {
  displayName: null,
  userNumber: null,
  isLoading: false,
  currentRoom: '',
  animal: '',
  color: '',
}

export const user = createModel<RootModel>()({
  state: initialState,
  reducers: {

    LOAD_USER_REQUEST: (state: User) => {
      return {
        ...state,
        isLoading: true,
      }
    },

    LOAD_USER: (state: User) => {
      try {
        const user = JSON.parse(sessionStorage.getItem('user') || '{}');
        return {
          ...state,
          displayName: user.displayName,
          userNumber: user.userNumber,
          animal: user.animal,
          color: user.color,
          currentRoom: user.currentRoom,
        }
      } catch (e) {
        return state;
      }
    },

    LOAD_USER_FAIL: (state: User) => {
      return {
        ...state,
        isLoading: false,
      }
    },

    CREATE_USER_REQUEST: (state: User) => {
      return {
        ...state,
        isLoading: true
      }
    },

    CREATE_USER_SUCCESS: (state: User, payload: User) => {
      sessionStorage.setItem('user', JSON.stringify(payload));
      return {
        ...state,
        animal: payload.animal,
        color: payload.color,
        userNumber: payload.userNumber,
        displayName: payload.displayName,
        isLoading: false,
        socket,
      }
    },

    CREATE_USER_FAIL: (state: User) => {
      return {
        ...state,
        isLoading: false,
      }
    },

    GET_USER_REQUEST: (state: User) => {
      return {
        ...state,
        isLoading: true
      }
    },

    GET_USER_SUCCESS: (state: User, payload: User) => {
      return {
        ...state,
        isLoading: false,
        userNumber: payload.userNumber,
        displayName: payload.displayName,
        animal: payload.animal,
        color: payload.color,
      }
    },

    GET_USER_FAIL: (state: User) => {
      return {
        ...state,
        isLoading: false,
      }
    },

    RESET_USER: (state: User) => {
      return initialState;
    }

  },
  effects: (dispatch) => ({
    async createUser(payload, rootState) {
      dispatch.user.CREATE_USER_REQUEST();

      const response = await createUser(payload);
      if (response.statusText !== 'OK') {
        dispatch.user.CREATE_USER_FAIL();
        return;
      }
      localStorage.setItem('user', response.data.data.userNumber);

      dispatch.user.CREATE_USER_SUCCESS(response.data.data);
    },

    async getUser() {
      dispatch.user.GET_USER_REQUEST();

      const response = await getUser();

      if (response.statusText !== 'OK') {
        dispatch.user.GET_USER_FAIL();
        return false;
      }

      if (response.data?.success === false) {
        dispatch.user.RESET_USER();
        sessionStorage.removeItem('user');
        return false;
      }

      dispatch.user.GET_USER_SUCCESS(response.data.data);
      return true;
    },

    async loadUser() {
      dispatch.user.LOAD_USER_REQUEST();
      try {
        dispatch.user.LOAD_USER();
      } catch (e) {
        dispatch.user.LOAD_USER_FAIL();
      }
    }
  }),
})
