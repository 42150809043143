import { FC, useEffect, useState } from 'react';
import Animal from "react-animals";
import { motion } from 'framer-motion';
import { getRandomArbitrary } from '../utils';

interface Props {
  userName: string;
  myPoint: string;
  color: string;
  animal: string
}

const UserItem: FC<Props> = ({ userName, myPoint, animal, color }) => {
  const [top, setTop] = useState<number>(getRandomArbitrary(300, window.innerHeight - 300))
  const [left, setLeft] = useState<number>(getRandomArbitrary(200, window.innerWidth - 200))

  useEffect(() => {
    const topDirection = Math.random() > 0.5 ? 1 : -1;
    const leftDirection = Math.random() > 0.5 ? 1 : -1;

    setTop((prev) => {
      return prev += topDirection * 5
    })
    setLeft((prev) => {
      return prev += leftDirection * 5
    })

  }, [myPoint])

  return (
    <motion.div style={{
      position: 'absolute',
      left,
      top,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      paddingTop: '5px',
      paddingBottom: '5px',
      margin: '10px'
    }}>
      <span style={{ marginBottom: 7, fontFamily: 'monospace' }}>{myPoint}</span>
      <Animal name={animal} color={color} />
      <span style={{ fontFamily: 'monospace' }}>{userName}</span>
    </motion.div>
  )
}

export default UserItem;
