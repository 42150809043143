import { FC, useState } from 'react';
interface Props {
  point?: string;
  onClick: (a: any) => void;
}
const POINTS = ['1', '2', '3', '5', '8', '13', '21', '34'];

const PointOptionSheet: FC<Props> = ({ onClick }) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      position: 'fixed',
      bottom: 100,
      width: '100%'

    }}>
      {POINTS.map(point => (<PointButton onClick={onClick} point={point} key={point} />))}
    </div>
  )
}

export default PointOptionSheet;

const PointButton: FC<Props> = ({ point, onClick }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <span key={point} onClick={() => onClick(point)} style={{
      width: 50,
      height: 50,
      marginLeft: 10,
      marginRight: 10,
      borderRadius: '50%',
      backgroundColor: isHover ? '#e3eeff' : '#c0d8fa',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 25,
      fontFamily: 'monospace',
      cursor: 'pointer',
    }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {point}
    </span>
  )
}