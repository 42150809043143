import { FC, useState } from "react";
import Modal from 'react-modal';
import socket from "../socket";
Modal.setAppElement('#root');

interface Props {
  showRoomNotFound: boolean;
  isOpen: boolean;
}

const CreateRoomModal: FC<Props> = ({ isOpen, showRoomNotFound }) => {
  const [roomName, setRoomName] = useState<string>('');
  const [roomNumber, setRoomNumber] = useState<string>('');

  return (
    <Modal
      isOpen={isOpen}
      style={{
        content: {
          width: '30%',
          height: 250,
          position: 'absolute',
          marginLeft: 'auto',
          marginRight: 'auto',
          left: 0,
          right: 0,
          fontFamily: 'monospace',
          borderRadius: 7,
        }
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, width: '100%', height: '80%', flexWrap: 'wrap' }}>
        <div>
          CREATE A ROOM OR ENTER A ROOM
        </div>
        <div style={{ flexBasis: '100%', width: 0, height: 0 }} />
        <label style={{ margin: 30 }}>
          ROOM NAME:
          <input type="text" name="roomName" value={roomName} onChange={(e) => setRoomName(e.target.value)} style={{ marginLeft: 5 }} />
        </label>
        <div style={{ flexBasis: '100%', width: 0, height: 0 }} />
        <input disabled={!roomName} style={{ fontFamily: 'monospace' }} type='button' value='CREATE' onClick={() => {
          socket.emit('onUserCreateRoom', {
            roomName,
            userNumber: JSON.parse(sessionStorage.getItem('user') || '{}').userNumber
          });
        }} />
        <div style={{ flexBasis: '100%', width: 0, height: 0, marginBottom: 20 }} />
        --- OR ---
        <div style={{ flexBasis: '100%', width: 0, height: 0 }} />
        <label style={{ margin: 30 }}>
          ROOM NUMBER:
          <input type="text" name="roomName" value={roomNumber} onChange={(e) => setRoomNumber(e.target.value)} style={{ marginLeft: 5 }} />
        </label>
        <div style={{ flexBasis: '100%', width: 0, height: 0 }} />
        {showRoomNotFound && (<>
          <span style={{ color: 'red' }} >Room Not Found!!</span>
          <div style={{ flexBasis: '100%', width: 0, height: 0 }} />
        </>)}
        <input disabled={!roomNumber} type='button' style={{ fontFamily: 'monospace' }} value='ENTER' onClick={() => {
          socket.emit('onNewUserEnterRoom', {
            roomNumber,
            userNumber: JSON.parse(sessionStorage.getItem('user') || '{}').userNumber
          });
        }} />
      </div>

    </Modal>
  )
}

export default CreateRoomModal;