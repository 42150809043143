import { FC, useState } from 'react';
import { RootState, RootDispatch } from '../types/store';
import PointOptionSheet from './PointOptionSheet';
import { useSelector, useDispatch } from 'react-redux';
import UserItem from './UserItem';
import socket from '../socket';
import { OtherUser } from '../Store/Models/room';

interface Props {
  roomNumber: string;
  roomName: string;
}

const Room: FC<Props> = ({ roomName, roomNumber }) => {
  const dispatch = useDispatch<RootDispatch>()
  const [shownPoint, setShownPoint] = useState<string | null>(null);

  const { displayName, animal, color, userNumber } = useSelector(
    (state: RootState) => state.user
  );

  const { users, isShown } = useSelector(
    (state: RootState) => state.room
  );
  return (
    <div>
      <div style={{ backgroundColor: '#d7e5fa', display: 'inline-block', borderRadius: '5px', padding: '6px', fontFamily: 'monospace' }}>
        <div style={{ marginBottom: 6 }}>
          <span>ROOM NAME: {roomName}</span>
          <span style={{ marginLeft: 10 }}>ROOM NUMBER: {roomNumber}</span>
          <br />
        </div>


        <input style={{ marginRight: 5, fontFamily: 'monospace' }} disabled={isShown} type='button' value='REVEAL' onClick={(e) => {
          e.preventDefault();
          if (!userNumber) return
          dispatch.room.reveal({ roomNumber, userNumber })
        }} />


        <input style={{ fontFamily: 'monospace' }} disabled={!isShown} type='button' value='RESET' onClick={(e) => {
          dispatch.room.reset(roomNumber)
          e.preventDefault();
        }} />
      </div>
      <UserItem
        userName={displayName as string}
        myPoint={shownPoint ||
          users?.find((name) => name.displayName === displayName)?.points ||
          '0'}
        animal={animal}
        color={color}
      />
      {
        users?.filter((name) => name.displayName !== displayName).map((user, index) => {
          return (
            <UserItem
              userName={user.displayName}
              key={index}
              myPoint={isShown ? user.points : '?'}
              animal={user.animal}
              color={user.color}
            />
          )
        })
      }

      <PointOptionSheet onClick={(point) => {
        if (isShown) return
        setShownPoint(point)
        socket.emit('onUserSubmitPoint', point)
        const room = JSON.parse(sessionStorage.getItem('room') || '{}')
        sessionStorage.setItem('room', JSON.stringify({
          ...room,
          users: room.users
            .map((user: OtherUser) => {
              if (user.displayName === displayName) {
                return {
                  ...user,
                  points: point,
                }
              }
              return user;
            })
        }))
      }} />
    </div >
  )
}

export default Room;
