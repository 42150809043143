import './App.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import { RootDispatch, RootState } from './types/store';
import CreateUserModal from './components/CreateUserModal';
import CreateRoomModal from './components/CreateRoomModal';
import {
  Switch,
  Route,
} from "react-router-dom";
import socket from './socket';

import Room from './components/Room';

function App() {
  const dispatch = useDispatch<RootDispatch>()
  const [showRoomNotFound, setShowRoomNotFound] = useState<boolean>(false)
  let history = useHistory()

  const { roomNumber, roomName, isLoading: roomIsLoading } = useSelector(
    (state: RootState) => state.room
  );

  const { userNumber, isLoading: userIsLoading } = useSelector(
    (state: RootState) => state.user
  );

  useEffect(() => {
    dispatch.user.loadUser()
    dispatch.room.loadRoom()
  }, [roomIsLoading, userIsLoading])

  useEffect(() => {
    socket.auth = JSON.parse(sessionStorage.getItem('user') || '{}')
    socket.connect();
    socket.on('onRoomUpdateUser', (data) => {
      dispatch.room.USER_ENTER_ROOM(data);
    })
    socket.on('onCreateUserSuccess', (user) => {
      dispatch.user.CREATE_USER_SUCCESS(user);
    });

    socket.on('onRoomEnter', (data) => {
      dispatch.room.ENTER_ROOM_SUCCESS(data, history);
    });

    socket.on('onRoomCreate', (data) => {
      dispatch.room.CREATE_ROOM_SUCCESS(data, history);
    });

    socket.on('onRoomUserSubmitPoint', (data) => {
      dispatch.room.RECEIVE_ROOM_UPDATE(data);
    })

    socket.on('onRevealScore', (data) => {
      dispatch.room.REVEAL_SCORE(data)
    })

    socket.on('onResetRoom', (data) => {
      dispatch.room.RESET_ROOM_SUCCESS(data)
    })

    socket.on('onRoomEnterError', () => {
      setShowRoomNotFound(true)
    })
  }, [roomIsLoading, userIsLoading]);

  useEffect(() => {
    dispatch.room.getRoom();
  }, [dispatch.room]);

  useEffect(() => {
    dispatch.user.getUser();
  }, [dispatch.user]);



  return (
    <div style={{ height: '100vh', backgroundColor: '#f5f9ff' }}>
      <CreateRoomModal isOpen={!roomNumber} showRoomNotFound={showRoomNotFound} />
      <CreateUserModal isOpen={!userNumber} />
      <Switch>
        <Route path="/:roomNumber" children={<Room roomNumber={roomNumber} roomName={roomName} />} />
      </Switch>
      <div style={{ marginTop: 5, marginLeft: 5 }}>
        <a href="https://www.buymeacoffee.com/hkisthebest" target="_blank" rel="noreferrer">
          <img src="https://cdn.buymeacoffee.com/buttons/v2/default-blue.png" alt="Buy Me A Coffee" style={{ height: 35, width: 130 }} />
        </a>
      </div>
    </div>
  );
}

export default App;
