import axios from 'axios';
import { User } from '../Store/Models/user';

const instance = axios.create({
  baseURL: `https://animal-story-point.com/api`,
  timeout: 3000,
  headers: {
    'Content-Type': 'application/json',
  },
})

instance.interceptors.request.use(function(config) {
  const token = sessionStorage.getItem('user') || '{}';
  const user = JSON.parse(token);
  if (config && config.headers && user && user.userNumber) {
    config.headers.Authorization = user.userNumber;
  }
  return config;
});

export const createUser = (displayName: Record<string, string>) => instance.post('user', displayName);
export const getUser = () => instance.get('user');

export const createRoom = (roomName: string) => instance.post('room', { name: roomName });
export const enterRoom = (roomNumber: string) => instance.put(`room/${roomNumber}/me`);
export const getRoom = (roomNumber: string) => instance.get(`room/${roomNumber}`);
export const revealScore = (roomNumber: string, userNumber: User["userNumber"]) => instance.put(`room/reveal?roomNumber=${roomNumber}&userNumber=${userNumber}`);
export const resetRoom = (roomNumber: string) => instance.put(`room/reset?roomNumber=${roomNumber}`);

