
import { createModel } from '@rematch/core'
import { User } from './user';
import { getRoom, resetRoom, revealScore } from '../../api';

import { RootModel } from '.';

export interface OtherUser {
  displayName: string;
  points: string;
  animal: string;
  color: string;
}

export interface Room {
  roomNumber: string;
  users: OtherUser[];
  isLoading: boolean;
  roomName: string;
  isShown: boolean
}

const initialState: Room = {
  roomNumber: '',
  users: [],
  isLoading: false,
  roomName: '',
  isShown: false,
}

export const room = createModel<RootModel>()({
  state: initialState,
  reducers: {

    LOAD_ROOM_REQUEST: (state: Room) => {
      return {
        ...state,
        isLoading: true,
      }
    },

    LOAD_ROOM: (state: Room) => {
      try {
        const room = JSON.parse(sessionStorage.getItem('room') || '{}');
        return {
          ...state,
          isLoading: false,
          isShown: room.isShown,
          roomNumber: room.roomNumber,
          users: room.users,
          roomName: room.roomName,
        }
      } catch (e) {
        return state;
      }
    },

    LOAD_ROOM_FAIL: (state: Room) => {
      return {
        ...state,
        isLoading: false,
      }
    },

    CREATE_ROOM_REQUEST: (state: Room) => {
      return {
        ...state,
        isLoading: true,
      }
    },

    CREATE_ROOM_SUCCESS: (state: Room, payload: { currentRoom: Room, user: User }, history) => {
      try {
        history.push(`/${payload.currentRoom.roomNumber}`);
        sessionStorage.setItem('room', JSON.stringify(payload.currentRoom));
        sessionStorage.setItem('user', JSON.stringify({
          ...JSON.parse(sessionStorage.getItem('user') || '{}'),
          currentRoom: payload.currentRoom.roomNumber
        }));
        return {
          ...state,
          isLoading: false,
          roomNumber: payload.currentRoom.roomNumber,
          users: payload.currentRoom.users,
          roomName: payload.currentRoom.roomName,
          isShown: payload.currentRoom.isShown,
        }
      } catch (e) {
        return state;
      }
    },

    CREATE_ROOM_FAIL: (state: Room) => {
      return {
        ...state,
        isLoading: false,
      }
    },

    ENTER_ROOM_REQUEST: (state: Room) => {
      return {
        ...state,
        isLoading: true,
      }
    },

    ENTER_ROOM_SUCCESS: (state: Room, payload: Room, history) => {
      try {
        history.push(`/${payload.roomNumber}`);
        sessionStorage.setItem('room', JSON.stringify(payload));
        sessionStorage.setItem('user', JSON.stringify({
          ...JSON.parse(sessionStorage.getItem('user') || '{}'),
          currentRoom: payload.roomNumber
        }));
        return {
          ...state,
          isLoading: false,
          roomNumber: payload.roomNumber,
          users: payload.users,
          roomName: payload.roomName,
          isShown: payload.isShown,
        }
      } catch (e) {
        return state;
      }
    },

    USER_ENTER_ROOM: (state: Room, payload: Room) => {
      try {
        sessionStorage.setItem('room', JSON.stringify(payload));
        return {
          ...state,
          users: payload.users
        }
      } catch (e) {
        return state;
      }
    },

    ENTER_ROOM_FAIL: (state: Room) => {
      return {
        ...state,
        isLoading: false,
      }
    },

    GET_ROOM_REQUEST: (state: Room) => {
      return {
        ...state,
        isLoading: true,
      }
    },

    GET_ROOM_SUCCESS: (state: Room, payload: Room) => {
      sessionStorage.setItem('room', JSON.stringify(payload));
      return {
        ...state,
        isLoading: false,
        roomName: payload.roomName,
        roomNumber: payload.roomNumber,
        users: payload.users,
        isShown: payload.isShown
      }
    },

    GET_ROOM_FAIL: (state: Room) => {
      return {
        ...state,
        isLoading: false,
      }
    },

    RECEIVE_ROOM_UPDATE: (state: Room, payload: string) => {
      const room = JSON.parse(sessionStorage.getItem('room') || '{}')
      room.users = room.users.map((user: OtherUser) => {
        if (user.displayName === payload) {
          user.points = '?';
        }
        return user;
      })
      sessionStorage.setItem('room', JSON.stringify(room));
      return {
        ...state,
        users: state.users.map(user => {
          if (user.displayName === payload) {
            user.points = '?';
          }
          return user;
        })
      }
    },



    REVEAL_SCORE: (state: Room, payload: Room) => {
      sessionStorage.setItem('room', JSON.stringify(payload));
      return {
        ...state,
        ...payload,
      };
    },
    RESET_ROOM_REQUEST: (state: Room) => {
      return {
        ...state,
        isLoading: true,
      }
    },
    RESET_ROOM_SUCCESS: (state: Room, payload: Room) => {
      return {
        ...state,
        ...payload,
      }
    },
    RESET_ROOM_FAIL: (state: Room) => {
      return {
        ...state,
        isLoading: false,
      }
    }
  },
  effects: (dispatch) => ({
    async getRoom() {
      dispatch.room.GET_ROOM_REQUEST();
      try {
        const { roomNumber } = JSON.parse(sessionStorage.getItem('room') as string);
        const response = await getRoom(roomNumber);
        if (response.statusText !== 'OK') {
          sessionStorage.removeItem('room')
          dispatch.room.GET_ROOM_FAIL();
        }

        if (!response.data.success) {
          sessionStorage.removeItem('room')
          dispatch.room.GET_ROOM_FAIL();
          return;
        }

        dispatch.room.GET_ROOM_SUCCESS(response.data.data);

      } catch (e) {
        dispatch.room.GET_ROOM_FAIL();
        return;
      }
    },

    async reveal({ roomNumber, userNumber }: { roomNumber: string, userNumber: User["userNumber"] }) {
      await revealScore(roomNumber, userNumber)
    },

    async reset(roomNumber: string) {
      dispatch.room.RESET_ROOM_REQUEST();
      try {
        const response = await resetRoom(roomNumber);

        if (!response.data.success) {
          dispatch.room.RESET_ROOM_FAIL();
          return;
        }

        dispatch.room.RESET_ROOM_SUCCESS(response.data.data);

      } catch (e) {

        dispatch.room.RESET_ROOM_FAIL();
      }
    },

    async loadRoom() {
      dispatch.room.LOAD_ROOM_REQUEST();
      try {
        dispatch.room.LOAD_ROOM();
      } catch (e) {
        dispatch.room.LOAD_ROOM_FAIL();
        return;
      }
    }
  })
})
