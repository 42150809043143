import { FC, useState } from "react";
import Modal from 'react-modal';
import socket from '../socket';
import { getRandomArbitrary } from '../utils';
Modal.setAppElement('#root');

interface Props {
  isOpen: boolean
}

const CreateUserModal: FC<Props> = ({ isOpen }) => {
  const [displayName, setDisplayName] = useState<string>('');
  return (
    <Modal
      isOpen={isOpen}
      style={{
        content: {
          fontFamily: 'monospace',
          width: '30%',
          height: 150,
          position: 'absolute',
          marginLeft: 'auto',
          marginRight: 'auto',
          left: 0,
          right: 0,
          borderRadius: 7,
        }
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, width: '100%', height: '70%', flexWrap: 'wrap' }}>
        <div>
          CREATE A USER
        </div>
        <div style={{ flexBasis: '100%', width: 0, height: 0 }} />
        <label style={{ margin: 30 }}>
          DISPLAY NAME:
          <input type="text" name="displayName" value={displayName} onChange={(e) => setDisplayName(e.target.value)} style={{ marginLeft: 5 }} />
        </label>
        <div style={{ flexBasis: '100%', width: 0, height: 0 }} />
        <input disabled={!displayName} type='button' value='CREATE' style={{ fontFamily: 'monospace' }} onClick={() => {
          const animal = ANIMALS[getRandomArbitrary(0, 27)]
          const color = COLORS[getRandomArbitrary(0, 6)]

          socket.emit('onCreateUser', { animal, color, displayName });
          sessionStorage.setItem('user', JSON.stringify({
            ...JSON.parse(sessionStorage.getItem('user') || '{}'),
            animal,
            color,
          }))
        }} />
      </div>

    </Modal>
  )
}

export default CreateUserModal;



const COLORS = ['red', 'orange', 'yellow', 'green', 'purple', 'teal']

const ANIMALS = [
  'alligator',
  'badger',
  'bat',
  'beaver',
  'buffalo',
  'camel',
  'crow',
  'dinosaur',
  'duck',
  'elephant',
  'fox',
  'frog',
  'giraffe',
  'gopher',
  'iguana',
  'koala',
  'monkey',
  'moose',
  'penguin',
  'python',
  'rabbit',
  'raccoon',
  'rhino',
  'sheep',
  'squirrel',
  'tiger',
  'turtle',
]